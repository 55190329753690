.text-with-image {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  padding-bottom: 48px;
  border-bottom: 1px solid #303030;
  margin-bottom: 24px;

  &._only-content {
    display: block;
  }

  .block-img {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .block-link {
    display: inline-flex;
    align-items: center;
    font-size: 20px;
    color: #303030;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 40px;
    transition: 0.32s ease-in-out;

    .icon {
      margin-left: 9px;
      color: #E11606;
    }

    &:hover {
      color: #E11606;
    }
  }

  .img-wrapper {
    margin-top: auto;
  }

  .img-mobile {
    display: none;
  }
}

@media(max-width: 1600px) {
  .text-with-image {
    .block-link {
      font-size: 16px;
    }
  }
}

@media(max-width: 991px) {
  .text-with-image {
    display: block;

    .img-wrapper {
      display: none;
    }

    .block-link {
      margin-bottom: 20px;
    }

    .img-mobile {
      display: block;
      img{
        max-width: 200px;
      }
    }
  }
}

@media(max-width: 767px) {
  .text-with-image {
    .block-link {
      font-size: 16px;
      line-height: 1.25;
      font-weight: 500;
    }
    .text-formatted{
      max-height: 460px;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}
.site-workTime {
    line-height: 1.25;
    margin: 0 -24px;
    padding: 20px 24px;
    border-bottom: 1px solid #303030;

    .title {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 4px;
    }

    .text {
        font-size: 20px;
        font-family: 'Open Sans', sans-serif;


        > * {
            margin-top: 8px;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            display: block;
        }
    }


}

@media(max-width: 1199px) {
    .site-workTime {
        padding: 16px;
        margin: 0;
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;

        .title {
            font-size: 12px;
            font-weight: 600;
            line-height: 1.333;
            flex: 0 0 100%;
            max-width: 100%;
        }

        .text {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.22;
        }

        > * {
            margin-top: 0;
            margin-left: 4px;
        }
    }
}
.market-page{
  .text-with-image{
    padding-bottom: 0;
    margin-bottom: 48px;
    border-bottom: 0;
  }
  .page-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon{
      min-width: 32px;
      height: 32px;
      font-size: 32px;
      color: #EB5757;
      cursor: pointer;
      transition: 0.32s ease;
      &:hover{
        color: #131313;
      }
    }
    &:after{
      min-width: 32px;
      height: 32px;
      content: '';
    }
  }
}

.market-info {
  display: flex;
  font-size: 20px;
  line-height: 1.2;
  color: #303030;

  .info-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  .info-item {
    padding: 15px 24px;
    flex: 1;
    border: 1px solid #303030;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-left: none;
    &:nth-child(4n){
      border-right: none;
    }

    &.info-description {
      flex: 0 0 40%;
      max-width: 30%;
      line-height: 1.5;

      .highlight {
        color: #E11606;
        text-decoration: underline;
      }
    }
  }

  .info-text {
    .small {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
    }
  }

  .section-link {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
    padding: 0;

    .icon {
      font-size: 20px;
      margin-left: 15px;
    }
  }
}

@media(max-width: 1600px) {
  .market-info {
    font-size: 16px;

    .info-title {
      font-size: 14px;
    }

    .info-item {
      padding: 10px 15px;
    }

    .section-link {
      font-size: 14px;
      .icon{
        font-size: 14px;
        margin-left: 8px;
      }
    }
  }
}

@media (max-width: 992px){
  .market-info {
    display: block;
    .info-item{
      &.info-description,&{
        flex: 0 1 auto;
        max-width: none;
      }
      .info-title{

      }
    }
  }
}
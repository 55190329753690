.site-social {
  display: flex;
  margin: 0 -24px;
  border-bottom: 1px solid #303030;

  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-height: 90px;
    transition: 0.32s ease-in-out;
    text-decoration: none;

    .icon{
      font-size: 32px;
      color: #303030;
    }

    img {
      transition: 0.32s ease-in-out;
    }

    &:not(:last-child) {
      border-right: 1px solid #303030;
    }

    &:hover {
      background: #303030;
      .icon {
        color: #fff;
      }
    }
  }
}

@media(max-width: 1199px) {
  .site-social {
    .link {
      min-height: 72px;
    }
  }
}
.site-address {
  margin: 0 -24px;
  padding: 20px 24px;
  border-top: 1px solid #303030;
  border-bottom: 1px solid #303030;
  line-height: 1.2;

  .title {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  .text {
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
  }
}
@media(max-width: 1199px) {
  .site-address {
    padding: 16px;
    margin: 0;

    .title {
      font-size: 12px;
      font-weight: 600;
      line-height: 1.333;
    }

    .text {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.22;
    }
  }
}
.market-item {
  position: relative;
  display: block;
  color: #fff;
  text-decoration: none;

  &._empty {
    position: relative;
    height: 100%;
    overflow: hidden;




    &:after {
      content: '';
      display: block;
      height: 110%;
      width: 110%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      -webkit-clip-path: polygon(0 99.5%, 99.5% 0, 100% 0.5%, 0.5% 100%);
      clip-path: polygon(0 99.5%, 99.5% 0, 100% 0.5%, 0.5% 100%);
      background: #303030;
    }
  }


  .img-wrapper {
    overflow: hidden;
    position: relative;
    height: 27.7vw;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background: linear-gradient(180deg, rgba(48, 48, 48, 0) 41.56%, rgba(0, 0, 0, 0.84) 100%);
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      transition: 3s linear;
    }
  }

  .info-block {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px;
    text-align: center;
    z-index: 2;
  }

  .title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.25;
    color: #fff;
    text-transform: uppercase;
  }

  .description {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }

  &:hover {
    .img-wrapper {
      img {

        transform: scale(1.1);
      }
    }
  }
}

.catalog-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-top: 1px solid #303030;
  margin-top: -1px;
  border-bottom: 1px solid #303030;
}

@media(max-width: 991px) {
  .market-item {
    .img-wrapper {
      height: 50vw;
    }

    .title {
      font-size: 20px;
      line-height: 1.2;
      margin-bottom: 4px;
    }

    .info-block {
      padding: 21px;
    }

    .description {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.333;
    }
  }
}

@media(max-width: 767px) {
  .market-item {
    .img-wrapper {
      height: 300px;
    }
  }
  .catalog-list {
    display: block;
  }
}
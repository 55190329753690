.schema-info-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #303030;

  .schema-info {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 1.5;
    font-family: "Open Sans", sans-serif;
    border-right: 1px solid #303030;

    .icon {
      font-size: 32px;
      color: #E11606;
      margin-right: 16px;
    }
  }

  .schema-title {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.25;
    padding-top: 32px;
    padding-bottom: 32px;
    text-transform: uppercase;
  }
}

.schema-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #303030;
}

.schema-image-wrapper {
  position: relative;
  border-right: 1px solid #303030;
  overflow: hidden;
  padding: 28px;

  .react-transform-component {
    width: 100%;

    > div {
      position: relative;
    }
  }

  svg {
    .fake-select {
      transition: 0.3s linear;
    }

    .active {
      fill: #E11606 !important;
    }
  }

  .schema-icon {
    position: absolute;
    filter: grayscale(100%);
    cursor: pointer;
    transition: 0.32s ease-in;
    z-index: 100;

    &:hover {
      filter: grayscale(0%);
    }

    &.active {
      filter: grayscale(0%);
    }
  }
}


.zoom-block {
  width: 160px;
  border-right: 1px solid #303030;
  border-bottom: 1px solid #303030;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: #fff;

  .zoom-control {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #303030;

    .zoom-plus,
    .zoom-minus {
      position: relative;
      height: 80px;

      cursor: pointer;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transition: 0.32s ease-in-out;
      }

      &:before {
        margin-top: -1px;
        width: 32px;
        margin-left: -16px;
        border-top: 2px solid #303030;
      }

      &:after {
        margin-top: -16px;
        margin-left: -1px;
        border-left: 2px solid #303030;
        height: 32px;
      }

      &:hover {
        &:before,
        &:after {
          border-color: #E11606;
        }
      }
    }

    .zoom-minus {
      border-right: 1px solid #303030;

      &:after {
        display: none;
      }
    }
  }

  .zoom-info {
    padding: 16px 15px 22px;
  }

  .zoom-title {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.75;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  .zoom-number {
    font-family: "Open Sans", sans-serif;
    font-size: 40px;
    line-height: 1;
    margin-bottom: 9px;

    span {
      font-size: 24px;
    }
  }

  .zoom-reset {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    transition: 0.3s ease-in-out;

    .icon {
      margin-right: 8px;
      color: #E11606;
    }

    &:hover {
      color: #E11606;
    }
  }
}

.schema-list-wrapper {
  max-width: 100%;
  padding-top: 16px;
  overflow: hidden;

  .schema-list-inner {
    display: flex;
    flex-wrap: nowrap;
    transition: 0.32s ease-in-out;

    &.list-2 {
      transform: translateX(-100%);
    }

    &.list-3 {
      transform: translateX(-200%);
    }

    &.list-4 {
      transform: translateX(-300%);
      //transition: none;
    }

    &.list-5 {
      transform: translateX(-400%);
    }

    > * {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

}

.schema-list {
  .list-item {
    padding: 24px 16px 24px 0;
    font-size: 32px;
    font-weight: 500;
    line-height: 1.25;
    position: relative;
    cursor: pointer;
    transition: 0.32s ease-in-out;
    text-transform: uppercase;


    &._last {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:after {
        display: none;
      }

      &._hide {
        display: none;
      }

      a {
        min-width: 32px;
        height: 32px;
        margin-left: 15px;
        background: #E11606;
        position: relative;
        border: 1px solid #E11606;
        transition: background 0.32s ease-in-out;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 0 8px 8px;
          border-color: transparent transparent transparent #fff;
          transition: border-color 0.32s ease-in-out;
        }

        &:hover {
          background: #fff;

          &:after {
            border-color: transparent transparent transparent #E11606;
          }
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #303030;
    }

    &:hover {
      color: #E11606;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 0 8px 8px;
      border-color: transparent transparent transparent #E11606;
    }
  }

  .list-return {
    display: inline-flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;
    margin-bottom: 12px;
    cursor: pointer;
    transition: 0.32s ease-in-out;
    margin-top: 24px;

    .icon {
      color: #E11606;
      font-size: 16px;
      margin-right: 8px;
    }

    &:hover {
      color: #E11606;
    }
  }

  .list-title {
    margin-bottom: 4px;
    font-size: 40px;
    line-height: 1.25;
    font-weight: 500;
    text-transform: uppercase;
  }
  .list-inner {
    display: flex;
    flex-direction: column;
    &._hide{
      display: none;
    }

    .list-title {
      margin: 10px 0;
    }
  }
}

.schema-search {
  display: flex;

  input {
    border: none;
    border-bottom: 1px solid #303030;
    border-radius: 0;
    background: #fff;
    color: rgba(48, 48, 48, 0.32);
    font-size: 24px;
    line-height: 1.333;
    flex: 1;
    padding: 16px 0;
    outline: none;
    transition: 0.32s ease-in-out;

    &:focus {
      border-color: #E11606;
      color: #303030;
    }
  }

  button {
    background: transparent;
    border: none;
    border-bottom: 1px solid #303030;
    outline: none;
    font-size: 24px;
    color: #303030;
    font-weight: 500;
    line-height: 1.333;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;

    .icon {
      color: #E11606;
      font-size: 24px;
    }

    &:hover {
      color: #E11606;
    }
  }
}

.mobile-schema-info {
  display: none;
}

@media(max-width: 1600px) {
  .schema-info-wrapper {
    .schema-info {
      font-size: 12px;

      .icon {
        font-size: 24px;
        margin-right: 8px;
      }
    }

    .schema-title {
      font-size: 24px;
    }
  }
  .schema-list {
    .list-item {
      font-size: 20px;
    }

    .list-title {
      font-size: 24px;
    }

    .list-return {
      font-size: 16px;
    }
  }
  .schema-search {
    input {
      font-size: 14px;
    }

    button {
      font-size: 14px;

      .icon {
        font-size: 16px;
      }
    }
  }
}

@media(max-width: 991px) {
  .schema-block {
    display: block;
  }
}

@media(max-width: 767px) {
  .schema-info-wrapper {
    display: none;

    .schema-info {
      line-height: 1.333;
      padding-top: 12px;
      padding-bottom: 12px;
      border-right: none;

      .icon {
        font-size: 24px;
        margin-right: 12px;
      }
    }

    .schema-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      padding-top: 17px;
      padding-bottom: 17px;
      text-align: center;
    }
  }
  .mobile-schema-info {
    display: block;
  }

  .zoom-block {
    position: static;
    width: auto;
    border-right: 0;
    border-top: 1px solid #303030;
    padding-left: 16px;
    padding-right: 16px;

    .zoom-control {
      display: none;
    }

    .zoom-info {
      display: flex;
      align-items: center;
      padding: 0;

      .zoom-title {
        max-width: 65px;
        font-size: 12px;
        line-height: 1.333;
        margin: 0;
        padding: 9px 0;
      }

      .zoom-number {
        font-size: 32px;
        margin: 0;
      }

      .zoom-reset {
        margin-left: auto;
        align-self: stretch;
        border-left: 1px solid #303030;
        padding: 0 17px;
      }
    }
  }
  .schema-image {
    margin-bottom: -90px;
    margin-top: -90px;

    svg {
      transform: scale(0.8) translateX(-69px);
    }
  }
  .schema-search {
    input {
      font-size: 16px;
      padding-bottom: 9px;
      padding-top: 9px;
    }
  }
  .schema-list-wrapper {
    padding-top: 8px;
  }
  .schema-list {
    margin-bottom: 40px;

    .list-return {
      margin-top: 16px;
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 1.28;

      .icon {
        font-size: 16px;
        margin-right: 7px;
      }
    }

    .list-title {
      font-size: 24px;
      line-height: 1.333;
      margin-bottom: 12px;
    }

    .list-item {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      padding-top: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid #303030;

      &:after {
        border-width: 6px 0 6px 6px;
      }
    }
  }
  .schema-image-wrapper {
    padding: 0;

    .react-transform-component {
      margin: 0 40px;
      width: auto;
      border-left: 1px solid #303030;
      border-right: 1px solid #303030;
    }
  }
}



.sidebar {
  flex: 0 0 320px;
  max-width: 320px;
  padding: 8px 24px;
  position: fixed;
  overflow: hidden;
  overflow-y: auto;
  top: 0;
  height: 100vh;
  border-right: 1px solid #303030;
  z-index: 2;
  background: #fff;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .mobile-text {
    display: none;
  }

  .language-list {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  .aside-logo {
    &._mobile{
      display: none;
    }
  }
}

.sidebar-overlay {
  display: none;
}

@media(max-width: 1199px) {
  .sidebar-overlay {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    pointer-events: none;
    z-index: 32;

    &.active {
      pointer-events: auto;
    }
  }
  .sidebar {
    position: fixed;
    height: auto;
    top: 72px;
    bottom: 0;
    left: 0;
    z-index: 33;
    width: 100%;
    flex: 0 1 auto;
    max-width: 360px;
    transform: translateX(-100%);
    opacity: 0;
    transition-delay: 0.1s;
    transition: 0.32s ease-out;
    padding: 0;
    display: block;
    flex-direction: column;
    border-right: none;

    &.active {
      transform: translateX(0);
      opacity: 1;
    }

    .aside-logo {
      order: 6;
      text-align: center;
      height: 150px;
      min-height: 150px;
      overflow: hidden;
      display: none;
      &._mobile{
        display: block;
      }
    }

    .mobile-text {
      display: block;
      font-size: 32px;
      color: #303030;
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;
      text-align: center;
      padding: 12px 16px;

    }

    .language-list {
      display: flex;
    }
  }
}

@media(max-width: 767px) {
  .sidebar {
    max-width: 100%;
  }
}
.language-list {
  border-left: 1px solid #303030;
  display: flex;
  font-size: 24px;
  line-height: 1.16;
  text-transform: uppercase;
  margin-left: auto;

  .item {
    min-width: 8.33vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 8px 24px;
    transition: 0.32s ease-in-out;

    &:not(:last-child) {
      border-right: 1px solid #303030;
    }

    &.active {
      text-shadow: 0 0 1px #303030;
      pointer-events: none;
    }

    &:hover:not(.active) {
      background: #303030;
      color: #fff;
    }
  }
}

@media(max-width: 1600px) {
  .language-list {
    font-size: 18px;

    .item {
      padding: 5px 15px;
    }
  }
}

@media(max-width: 1199px) {
  .language-list {
    font-size: 24px;
    line-height: 1.6;
    font-weight: 500;
    border-left: 0;
    border-bottom: 1px solid #303030;
    margin-bottom: 40px;
    margin-left: 0;

    .item {
      flex: 1;
      min-height: 72px;
    }
  }
}

.main-gallery {
  position: relative;
  margin-bottom: 24px;

  img {
    display: block !important;
  }
}

.gallery-arrow {
  position: absolute;
  bottom: 0;
  width: 80px;
  height: 80px;
  font-size: 20px;
  color: #fff;
  line-height: 1;
  background: #303030;
  transition: 0.32s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;

  &:hover {
    background: #E11606;
  }

  &._next {
    right: 0;
  }

  &._prev {
    left: 0;
  }
}

@media(max-width: 1600px) {
  .gallery-arrow {
    width: 50px;
    height: 50px;
    transition: 0.32s ease-in-out;
  }
}
@keyframes rightSlide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  45%  {
    transform: translate3d(calc(-100vw + 700px), 0, 0) ;
  }
  50% {
    transform: translate3d(calc(-100vw + 700px), 0, 0) rotateY(180deg);
  }
  95%{
    transform: translate3d(0, 0, 0) rotateY(180deg);
  }
  100%{
    transform: translate3d(0, 0, 0) rotateY(0deg);
  }
}

@keyframes leftSlide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  45%  {
    transform: translate3d(calc(100vw - 700px), 0, 0) ;
  }
  50% {
    transform: translate3d(calc(100vw - 700px), 0, 0) rotateY(180deg);
  }
  95%{
    transform: translate3d(0, 0, 0) rotateY(180deg);
  }
  100%{
    transform: translate3d(0, 0, 0) rotateY(0deg);
  }
}


@keyframes rightSlideMD {
  0% {
    transform: translate3d(0, 0, 0);
  }
  45%  {
    transform: translate3d(calc(-100vw + 240px), 0, 0) ;
  }
  50% {
    transform: translate3d(calc(-100vw + 240px), 0, 0) rotateY(180deg);
  }
  95%{
    transform: translate3d(0, 0, 0) rotateY(180deg);
  }
  100%{
    transform: translate3d(0, 0, 0) rotateY(0deg);
  }
}

@keyframes leftSlideMD {
  0% {
    transform: translate3d(0, 0, 0);
  }
  45%  {
    transform: translate3d(calc(100vw - 240px), 0, 0) ;
  }
  50% {
    transform: translate3d(calc(100vw - 240px), 0, 0) rotateY(180deg);
  }
  95%{
    transform: translate3d(0, 0, 0) rotateY(180deg);
  }
  100%{
    transform: translate3d(0, 0, 0) rotateY(0deg);
  }
}

@keyframes rightSlideMobile {
  0% {
    transform: translate3d(0, 0, 0);
  }
  45%  {
    transform: translate3d(calc(-100vw + 160px), 0, 0) ;
  }
  50% {
    transform: translate3d(calc(-100vw + 160px), 0, 0) rotateY(180deg);
  }
  95%{
    transform: translate3d(0, 0, 0) rotateY(180deg);
  }
  100%{
    transform: translate3d(0, 0, 0) rotateY(0deg);
  }
}

@keyframes leftSlideMobile {
  0% {
    transform: translate3d(0, 0, 0);
  }
  45%  {
    transform: translate3d(calc(100vw - 160px), 0, 0) ;
  }
  50% {
    transform: translate3d(calc(100vw - 160px), 0, 0) rotateY(180deg);
  }
  95%{
    transform: translate3d(0, 0, 0) rotateY(180deg);
  }
  100%{
    transform: translate3d(0, 0, 0) rotateY(0deg);
  }
}



.page-footer {
  color: #303030;
  margin-left: 320px;
  margin-top: auto;

  .top-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    position: relative;
  }

  .footer-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;

    > * {
      position: relative;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.5);
      filter: blur(10px);
    }
  }

  .images-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
  }

  .footer-image {
    display: block;

    &._first{
      animation: 15s linear 0s infinite normal none running leftSlide;
    }
    &._second {
      margin-top: 175px;
      animation: 15s linear 0s infinite normal none running rightSlide;
    }
  }

  .copyright {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 600;
    color: #303030;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #303030;
    padding-top: 22px;
    padding-bottom: 22px;

    .copyright-text {
      text-transform: uppercase;
    }
  }

  .footer-title {
    font-size: 64px;
    line-height: 1.125;
    margin-bottom: 24px;
    text-transform: uppercase;
  }

  .footer-address,
  .footer-phone {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 20px;
      color: #E21606;
    }
  }

  .footer-address {
    font-size: 32px;
    line-height: 1.25;
    margin-bottom: 24px;
  }

  .footer-phone {
    font-family: "Open Sans", Raleway, sans-serif;
    font-size: 32px;
    line-height: 1;
    color: #303030;
    text-decoration: none;
    transition: 0.32s ease-in-out;

    &:hover {
      color: #E21606;
    }
  }
}

@media(max-width: 1600px) {
  .page-footer {
    .footer-title {
      font-size: 42px;
    }

    .footer-address,
    .footer-phone {
      font-size: 20px;
    }

    .copyright {
      font-size: 12px;
    }
  }
}

@media(max-width: 1199px) {

  .page-footer {
    margin-left: 0;

    .footer-image {
      max-width: 240px;
      &._first {
        animation: 13.5s linear 0s infinite normal none running leftSlideMD;
      }
      &._second {
        margin-top: 50px;
        animation: 13.5s linear 0s infinite normal none running rightSlideMD;
      }
    }
  }
}




@media(max-width: 767px){
  .page-footer {
    .top-block{
      padding-top: 24px;
    }
    .footer-title{
      font-size: 32px;
      line-height: 1.25;
      margin-bottom: 12px;
    }
    .footer-image {
      max-width: 160px;
      &._first {
        animation: 7.5s linear 0s infinite normal none running leftSlideMobile;
      }
      &._second {
        margin-top: 50px;
        animation: 7.5s linear 0s infinite normal none running  rightSlideMobile;
      }
    }
  }
}


.address-block {
  display: grid;
  grid-template-columns: 40% 60%;
  border-bottom: 1px solid #303030;


 @media(min-width: 768px){
   .contact-map {
     margin-right: -24px;
   }

   &._maps {
     .block-content {
       border-left: 1px solid #303030;
       padding-top: 0;
     }
   }
 }

  .block-content {
    padding-right: 0;

    &._contact {
      display: flex;
    }
  }

  .content-item {
    font-family: "Open Sans";

    &:not(:last-child) {
      margin-right: 90px;
    }

    .content-title {
      font-size: 24px;
      line-height: 1;
      margin-bottom: 12px;
    }

    .content-links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 40px;
      font-weight: 600;
      line-height: 1.2;

      a {
        color: #303030;
        text-decoration: none;
        transition: 0.32s ease-in-out;
        text-transform: uppercase;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        &:hover {
          text-decoration: none;
          color: #E11606;
        }
      }
    }
  }


  .block-content,
  .contact-block-title {
    padding-top: 24px;
    padding-bottom: 48px;
  }

  .contact-block-title,
  .block-address {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
  }

  .contact-block-title {
    padding-right: 30px;
  }

  .block-address {
    margin-bottom: 10px;
  }

  .block-work {
    font-size: 32px;
    line-height: 1;
    font-family: "Open Sans", sans-serif;
  }

  .section-link {
    padding: 0;
    margin-top: 24px;
  }
}

.contacts-page {
  @media(min-width: 992px){
    .text-with-image {
      padding-top: 24px;
      display: grid;
      grid-template-columns: 40% 60%;
      grid-gap: 0;

      .text-formatted {
        padding-left: 24px;
      }
    }
  }
}


@media (max-width: 1800px) {
  .address-block {
    .content-item {
      &:not(:last-child) {
        margin-right: 40px;
      }

      .content-links {
        font-size: 32px;
      }
    }
  }
}

@media (max-width: 1600px) {
  .address-block {
    .block-content {
      &._contact {
        display: block;
      }
    }

    .contact-block-title,
    .block-address {
      font-size: 28px;
    }

    .block-work {
      font-size: 24px;
    }

    .content-item {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 20px;
      }

      .content-title {
        font-size: 20px;
      }

      .content-links {
        font-size: 24px;
      }
    }
  }
}

@media (max-width: 767px) {
  .address-block {
    display: block;

    &._maps{
      .block-content {
        padding-top: 0;
      }
    }

    .contact-block-title {
      padding: 20px 16px;
      border-bottom: 1px solid #303030;
      margin-left: -16px;
      margin-right: -16px;
      font-size: 24px;
    }

    .block-content {
      padding: 20px 0;
      .blocks-padding{
        padding-left: 0;
        padding-right: 0;
      }
    }

    .content-item {
      .content-title {
        font-size: 14px;
        margin-bottom: 12px;
      }

      .content-links {
        a {
          font-size: 24px;
          text-transform: none;

          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
    }

    .block-address {
      font-size: 20px;
      line-height: 1.2;
      margin-bottom: 4px;
    }
    .block-work{
      font-size: 16px;
    }
    .contact-map{
      margin-left: -16px;
      margin-right: -16px;
    }
    .section-link{
      font-weight: 600;
      font-size: 20px;
      line-height: 1;
    }
    .text-formatted {
      padding: 0;
    }
  }
  .contacts-page {
    .text-with-image {
      padding-top: 20px;
    }
  }
}
.page-wrapper {
    display: flex;
    min-height: 100vh;
}

.page-inner {
    flex: 1;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.blocks-padding {
    padding-left: 24px;
    padding-right: 24px;
}

.page-content {
    position: relative;
    padding-top: 90px;
    margin-left: 320px;
    background: #fff;
    min-height: 40vh;
}


.page-enter {
    opacity: 0;

}

.page-enter-active {
    opacity: 1;
    transition: opacity 300ms;
    transition-delay: 0.2s;

}

.page-exit {
    opacity: 1;
    position: absolute;
    right: 0;
    left: 0;
    top: 180px;
    transform: scale(1);
}

.page-exit-active {
    transform: scale(0.7);
    opacity: 0;
    transition: opacity 500ms,transform 400ms 100ms;
    position: absolute;
    right: 0;
}

.page-title {
    font-size: 72px;
    line-height: 1.11;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    border-bottom: 1px solid #303030;
    padding-top: 24px;
    padding-bottom: 24px;
}

.section-title {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.14;
    margin-bottom: 20px;
    text-transform: uppercase;

    &._center {
        text-align: center;
    }


    @media(min-width: 768px){
        &._small {
            font-size: 40px;
            line-height: 1.2;
            font-weight: 500;
        }

        &._block {
            font-size: 56px;
        }

        &._bold {
            font-weight: 600;
        }
    }
}

.section-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: 500;
    color: #303030;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: none;
    padding: 29px 20px;
    cursor: pointer;
    transition: 0.32s ease-in-out;

    .icon {
        margin-left: 16px;
        color: #E11606;
        font-size: 30px;
        &:before{
            transition: 0.22s ease-in;
        }
    }

    &:hover {
        color: #E11606;
        .icon {
            &:before{
                transform: rotate(45deg);
            }
        }
    }
}

.text-formatted {
    font-size: 24px;
    line-height: 1.5;
    color: #303030;

    & > *:last-child {
        margin-bottom: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: 32px;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 16px;
        text-transform: uppercase;
    }

    p, ol, ul {
        margin-bottom: 32px;
    }
}

@media(max-width: 1600px) {
    .page-content {
        padding-top: 51px;
    }
    .page-exit {
        top: 102px;
    }
    .section-title {
        font-size: 44px;

        &._small {
            font-size: 28px;
        }
    }
    .page-title {
        font-size: 52px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .text-formatted {
        font-size: 16px;

        h1, h2, h3, h4, h5, h6 {
            font-size: 25px;
            line-height: 1.2;
            margin-bottom: 8px;
        }

        p, ol, ul {
            margin-bottom: 18px;
        }
    }
    .section-link {
        padding: 15px 10px;
        font-size: 20px;

        .icon {
            font-size: 18px;
        }
    }
}
@media(max-width: 1199px){
    .blocks-padding {
        padding-left: 16px;
        padding-right: 16px;
    }
    .page-content{
        padding-top: 72px;
        margin-left: 0;
    }
}

@media(max-width: 767px){
    .page-title{
        font-size: 32px;
        font-weight: 500;
        line-height: 1.125;
        padding-top: 18px;
        padding-bottom: 18px;
    }
    .section-title {
        font-size: 32px;
        line-height: 1.125;
        margin-bottom: 20px;
        font-weight: 500;
    }
    .text-formatted {
        h1, h2, h3, h4, h5, h6 {
            font-size: 24px;
            font-weight: 600;
            line-height: 1.25;
        }
    }
    .section-link {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.45;
        padding-top: 18px;
        padding-bottom: 18px;
    }
}
.panel-text-list {
  padding-top: 24px;

  .text-with-image {
    margin-bottom: 0;
  }
}

.tabs-text {
  .tab-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    &._half {
      grid-template-columns: repeat(2, 1fr);
    }
    &._mobile {
      display: none;
    }
  }

  @media(min-width: 768px) {
    .tab {
      padding-top: 12px;
      padding-bottom: 12px;
      min-height: 90px;


      &:not(:nth-child(-n+4)) {
        border-top: 1px solid #303030;
      }
    }
  }
}

@media(max-width: 1600px) and (min-width: 768px) {
  .tabs-text {
    .tab {
      min-height: 72px;
    }
  }
}

@media(max-width: 767px) {
  .tabs-text {
    .tab-list {
      display: flex;
      flex-wrap: nowrap;
      &:not(._mobile) {
        display: none;
      }

      &._mobile {
        display: block;
      }
    }
  }
}
.page-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 320px;
  background: #fff;
  z-index: 31;
  color: #303030;

  .top-header,
  .bottom-header {
    border-bottom: 1px solid #303030;
    display: flex;
    height: 90px;
  }

  .big-name {
    font-size: 2.91vw;
    font-weight: 500;
    line-height: 1.17;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    @media(min-width: 1920px) {
      font-size: 56px;
    }
  }

  .mobile-header {
    display: none;
  }
}

.header-phone {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 8px 24px;
  border-left: 1px solid #303030;
  flex: 0 0 320px;
  margin-left: auto;

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    margin-bottom: 4px;
  }

  a {
    font-size: 24px;
    color: #303030;
    line-height: 1;
    font-family: "Open Sans", sans-serif;
    text-decoration: none;
    position: relative;
    transition: 0.32s ease-in-out;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: #2b8928;
      transform: translateX(-100%);
      transition: 0.32s ease-in-out;
    }

    &:hover {
      color: #2b8928;

      &:after {
        transform: translateX(0);
      }
    }
  }
}

.attention-block {
  padding: 8px 24px;
  display: flex;
  align-items: center;
  border-left: 1px solid #303030;
  margin-left: auto;

  .attention-alert {
    background: #FFFFFF;
    border: 1px solid #E11606;
    padding: 8px 12px;
    font-size: 16px;
    color: #E11606;
    line-height: 1.5;
    text-transform: uppercase;
    margin-right: 16px;
  }

  .attention-text {
    font-size: 16px;
    line-height: 1.25;
  }

  .attention-more {
    font-size: 20px;
    color: #303030;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: 0.32s ease-in-out;
    margin-left: 50px;

    .icon {
      color: #EB5757;
      margin-left: 8px;
    }

    &:hover {
      color: #EB5757;
    }
  }
}

@media(max-width: 1600px) {
  .page-header {
    .top-header,
    .bottom-header {
      height: 51px;
    }

    .big-name {
      padding: 5px 15px;
    }

    .header-phone {
      padding: 5px 15px;
    }
  }
  .header-phone {
    .title {
      font-size: 14px;
    }

    a {
      font-size: 18px;
    }
  }

  .attention-block {
    padding: 5px 15px;

    .attention-alert {
      font-size: 14px;
    }

    .attention-text {
      font-size: 12px;
    }

    .attention-more {
      margin-left: 15px;
      font-size: 18px;
    }
  }
}

@media(max-width: 1199px) {
  .page-header {
    left: 0;
    border-bottom: 1px solid #303030;

    .top-header {
      display: none;
    }

    .bottom-header {
      display: none;
    }

    .big-name {
      display: none;
    }

    .mobile-header {
      display: flex;
      align-items: center;
      background: #fff;
      height: 72px;
    }

    .mobile-logo {
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        color: #303030;
      }

      .logo-img {
        flex: 0 0 66px;
        max-width: 66px;
        margin-right: 7px;
      }

      .logo-text {
        font-size: 20px;
        line-height: 1.2;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .header-mobile-phone {
      margin-left: auto;
      margin-right: 24px;
      font-size: 24px;
      color: #303030;
      text-decoration: none;
    }

    .hamburger-box-outer {
      border-left: 1px solid #303030;
      align-self: stretch;
      padding-left: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .hamburger-box {
      width: 32px;
      height: 32px;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      .hamburger-inner {
        top: 50%;
        display: block;
        margin-top: -1px;

        &:before, &:after, & {
          content: '';
          position: absolute;
          width: 32px;
          height: 2px;
          transition: background-color 0.125s ease-in 0.175s;
          border-radius: 8px;
          background-color: #303030;
        }

        &:before {
          left: 0;
          transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.125s, left 0.125s ease-in 0.175s;
          top: -6px;
          top: -6px;
        }

        &:after {
          width: 22px;
          bottom: -6px;
          right: 0;
          transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.125s, right 0.125s ease-in 0.175s;
        }
      }

      &.active {
        .hamburger-inner {
          transition-delay: 0s;
          transition-timing-function: ease-out;
          background-color: transparent !important;

          &:before {
            top: -80px;
            left: -80px;
            transition: left 0.125s ease-out, top 0.05s linear 0.125s, transform 0.125s cubic-bezier(0.075, 0.82, 0.165, 1) 0.175s;
            transform: translate3d(80px, 80px, 0) rotate(45deg);
          }

          &:after {
            width: 32px;
            top: -80px;
            right: -80px;
            transition: right 0.125s ease-out, top 0.05s linear 0.125s, transform 0.125s cubic-bezier(0.075, 0.82, 0.165, 1) 0.175s;
            transform: translate3d(-80px, 80px, 0) rotate(-45deg);
          }
        }
      }
    }

    .attention-block {
      border-left: none;
    }
  }
}
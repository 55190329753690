.links-block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    order: 5;

    .links-item {
        display: flex;
        flex-direction: column;

        &:nth-child(odd) {
            border-right: 1px solid #303030;
        }

        .img-wrapper {
            height: 29.5vw;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .section-link {
            border-top: 1px solid #303030;
            border-bottom: 1px solid #303030;
        }
    }
}

@media(max-width: 991px){
    .links-block {
        .links-item {
            .img-wrapper {
                height: 50vw;
            }
        }
    }
}

@media(max-width: 767px){
    .links-block {
        display: block;
    }
}
.main-menu {
  display: flex;
  flex-direction: column;
  margin: 28px 0;

  .menu-item {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.25;
    color: #303030;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-decoration: none;
    transition: 0.32s ease-in-out;

    span {
      position: relative;
      display: inline-block;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 100%;
        border-bottom: 1px solid #E11606;
        transition: 0.32s ease-in-out;
      }
    }

    &:hover,
    &.active {
      color: #E11606;

      span {
        &:before {
          right: 0;
        }
      }
    }
  }
}

@media(max-width: 1199px){
  .main-menu {
    margin: 0;
    .menu-item {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      text-align: center;
      padding: 16px;
      border-top: 1px solid #303030;
      margin: 0;
    }
  }
}
.video-screen {
  position: relative;
  margin-bottom: 24px;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  video {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    display: block;
    width: 100%;
    height: auto;
  }

  .mute-icon {
    position: absolute;
    bottom: 24px;
    right: 24px;
    color: #fff;
    font-size: 40px;
    cursor: pointer;
    z-index: 10;
    line-height: 1;
    width: 40px;
    height: 40px;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &._on {
        transition-delay: 0.2s;
      }

      &._off {
        opacity: 0;
        transition: 0.2s ease-in-out;
      }

    }

    &:hover {
      .icon {
        &._on,
        &.off {
          transition: 0.32s ease-in-out;
          color: #E11606;
          transition-delay: 0s;
        }
      }
    }

    &.active {
      .icon {
        &._off {
          opacity: 1;
          transition: 0.32s ease-in-out;
          transition-delay: 0.2s;
        }

        &._on {
          opacity: 0;
          transition: 0.2s ease-in-out;
          transition-delay: 0s;
        }
      }
    }
  }
}


@media(max-width: 767px) {
  .video-screen {
    margin-bottom: 33px;

  }
}
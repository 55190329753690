.scroll-top-block {
  position: fixed;
  z-index: 9999;
  right: 48px;
  bottom: 48px;
  background: #303030;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  width: 96px;
  height: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.28s ease-in;

  &._active {
    pointer-events: auto;
    opacity: 1;
  }

  &._hide {
    pointer-events: none !important;
    opacity: 0 !important;
  }

  &:hover {
    .scroll-icon {
      animation-name: bounce-1;
      animation-timing-function: linear;
    }
  }

  .scroll-icon {
    font-size: 32px;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
  }

  .scroll-text {
    margin-top: 8px;
  }
}

@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

@media(max-width: 1600px) {
  .scroll-top-block {
    width: 56px;
    height: 56px;
    font-size: 12px;
    line-height: 16px;
    .scroll-icon {
      font-size: 24px;
    }
    .scroll-text {
      margin-top: 4px;
    }
  }
}

@media(max-width: 1200px) {
  .scroll-top-block {
    right: 0;
    bottom: 15px;
  }
}
.tab-list {
  display: flex;
  border-top: 1px solid #303030;
  border-bottom: 1px solid #303030;

  &._mobile {
    display: none;
  }
}

.product-gallery {
  .slick-track {
    align-items: stretch;
  }

  .market-item {
    height: 100%;
  }

  .slick-slide {
    height: auto;

    > div {
      position: relative;
      height: 100%;
    }
  }
}

.tab {
  all: initial;
  font-family: Raleway, "Open Sans", sans-serif;
  flex: 1;
  font-size: 24px;
  font-weight: 500;
  color: #303030;
  line-height: 1.4;
  padding: 28px 10px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: 0.32s ease-in-out;
  border-right: 1px solid #303030;

  &.active {
    background: #303030;
    color: #fff;
  }

  @media(pointer: fine) {
    &:hover {
      background: #303030;
      color: #fff;
    }
  }
}

.tab-markets-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
}

.panel-list {
  overflow: hidden;
  min-height: calc(27.69vw) !important;
}

.tab-control {
  display: flex;
  border-top: 1px solid #303030;
  border-bottom: 1px solid #303030;

  .prev,
  .next {
    display: flex;
    align-items: center;
    min-width: 268px;
    flex: 0 0 17%;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    color: #303030;
    padding-top: 10px;
    padding-bottom: 10px;

    &:before,
    &:after {
      width: 96px;
      height: 16px;
      background-size: cover;
    }
  }

  .prev {
    border-right: 1px solid #303030;

    &:before {
      content: '';
      background: url("../../images/icon/long-arrow-left.svg") center center no-repeat;
      margin-right: 16px;
    }
  }

  .next {
    border-left: 1px solid #303030;
    justify-content: flex-end;

    &:after {
      content: '';
      background: url("../../images/icon/long-arrow-right.svg") center center no-repeat;
      margin-left: 16px;
    }
  }

  .section-link {
    flex: 1;
    display: flex;
    align-items: center;
  }
}

@media(max-width: 1600px) {
  .tab {
    font-size: 14px;
    line-height: 1.2;
  }

  .panel-list {
    min-height: calc(27.69vw) !important;
  }

  .tab-control {
    .prev,
    .next {
      min-width: 180px;
      padding: 15px 10px;

      &:before,
      &:after {
        width: 35px;
        height: 8px;
      }

      &:before {
        background-position: left;
      }

      &:after {
        background-position: right;
      }
    }
  }
}

@media(max-width: 991px) {
  .panel-list {
    min-height: calc(50vw) !important;
  }
  .tab-control {
    .prev,
    .next {
      display: none;
    }
  }
}

@media(max-width: 767px) {
  .tab-list {
    &:not(._mobile) {
      display: none;
    }

    &._mobile {
      display: block;
    }

    .tab {
      white-space: nowrap;
      padding-left: 20px;
      padding-right: 20px;
      min-width: 30vw;
      text-align: center;
      box-sizing: border-box;
    }
  }
  .panel-list {
    min-height: 364px !important;

    .market-item {
      width: 300px;
    }
  }
}
